import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
import { EntityExplorerLayout } from '../../components/entities/entity-explorer-layout';

export default createGatsbyPageComponent('Topic', { layout: EntityExplorerLayout });

export const topicQuery = graphql`
  query TopicQuery($id: ID!) {
    platform {
      topic(id: $id) {
        __typename _schema { label pluralLabel }
        id slug name gptName __typename
        color { __typename id slug name value }
        outlineNumber
        trend { id slug name __typename }
        intro { text }
        description { text }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        policyTrends { id text }
        fundingTrends { id text }
        partnershipTrends { id text }
        libraryIntro { id text }
        trendIntro { id text }
        stakeholdersIntro { id text }
        instrumentsIntro { id text }
        technologiesIntro { id text }
        organisations { 
          id name slug url
          type { id name slug }
          technologies { id name slug }
          countries { id slug name path }
          logo { url static { childImageSharp { gatsbyImageData } } }
          openGraph { id image { id url } } 
        }
        embeds {
          citations {
            slug url name authors { id name slug } 
            authorShowsEtAl edition publication accessDate 
            startPage volume footnoteNumber year
          }
          imageAssets {
            id
            slug
            image {
              id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
        technologies {
          id name slug __typename _schema { label pluralLabel }
          openGraph { image { id url } }
          description { id text }
          url
          feedEntries {
            __typename _schema { label pluralLabel }
            id name slug url path websiteName
          }
          organisations { 
            id name slug url
            type { id name slug }
            technologies { id name slug }
            logo { url static { childImageSharp { gatsbyImageData } } }
            openGraph { id image { id url } } 
            topics { id name slug path }
          }
          instruments {
            id name slug path __typename _schema { label pluralLabel }
          }
        }
        instruments {
          id name slug __typename _schema { label pluralLabel }
          openGraph { image { id url } }
          description { text }
          types { id name slug path }
        }
        video {
          id name url
          thumbnail { id url static { childImageSharp { gatsbyImageData } } }
        }
        color { id name slug value darkValue veryDarkValue }
        banner {
          id
          image {
            id
            url
            thumbnails {
              mainBanner { id url }
            }
          }
        }
        fileAssets {
          id name slug file { id extension formattedSize url typeName } __typename
          thumbnail { id url static { childImageSharp { gatsbyImageData } } }
        }
        citations {
          __typename _schema { label pluralLabel }
          id path slug url name path
          authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
        }
        trend {
          id name path slug __typename
          outlineNumber
          color { id name slug value }
        }
        feedEntries {
          __typename _schema { label pluralLabel }
          id name slug url path
          types { id name slug }
          openGraph { image { url static { childImageSharp { gatsbyImageData } } } }
        }
        posts {
          __typename _schema { label pluralLabel }
          id name slug path __typename published
          openGraph { image { url static { childImageSharp { gatsbyImageData } } } }
          people { id name slug path }
        }
        subTopics {
          id name path outlineNumber slug __typename 
          color { id name value } 
          banner {
            id
            thumbnail { url static { childImageSharp { gatsbyImageData } } } 
          }
        }
        moderators {
          __typename id name slug position
          photo { id url }
          organisation { id name slug }
        }
      }
    }
  }
`;
